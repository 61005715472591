import { twMerge } from 'tailwind-merge';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Tenant } from 'models/Tenant';
import { Modal } from 'components/Modal/Modal';
import { Markdown } from 'components/Markdown/Markdown';

export interface FooterProps extends React.HTMLAttributes<HTMLElement> {
  tenantName: Tenant['bank_name'];
}

export const Footer = ({ className, tenantName, ...props }: FooterProps) => {
  const { t } = useTranslation();
  const [isImprintModalVisible, setIsImprintModalVisible] = useState(false);
  const footerClass = twMerge(
    'flex flex-col justify-between bg-lightSecondary px-11 py-8 text-center text-xs text-grayPrimary md:flex-row md:text-left md:text-sm',
    className
  );

  const toggleImprint = () => {
    setIsImprintModalVisible(!isImprintModalVisible);
  };

  return (
    <footer className={footerClass} {...props}>
      <div className="pb-2.5 md:pb-0">
        <button
          onClick={toggleImprint}
          className="underline"
          data-testid="imprint-button"
          type="button"
        >
          {t('common.titles.imprint')}
        </button>
        {` & `}
        <a
          href={t('common.data_privacy_link')}
          target="_blank"
          rel="noopener noreferrer"
          className="underline"
        >
          {t('common.titles.data_privacy')}
        </a>
      </div>
      <div className="text-center">
        <p>{t('common.bs_footer')}</p>
        <p>{t('common.footer_powered_by', { bank: tenantName })}</p>
      </div>
      <Modal
        title={t('common.titles.imprint')}
        onRequestClose={toggleImprint}
        isOpen={isImprintModalVisible}
        contentLabel={`${t('common.titles.imprint')} modal`}
      >
        <Markdown
          text={t('tenant_texts.imprint')}
          data-testid="imprint-text"
          className="text-xs text-grayPrimary"
        />
      </Modal>
    </footer>
  );
};
