import {
  ErrorBoundary as SentryErrorBoundary,
  ErrorBoundaryProps,
  FallbackRender
} from '@sentry/react';
import i18next from 'i18next/i18next';
import { IGNORED_ERRORS_FOR_FAILED_TO_FETCH_MODULES } from 'apm/sentryAgent';

import { Button } from 'components/Button/Button';
import { WarningIcon } from 'components/Icons/WarningIcon';

const ErrorBoundaryFallback: FallbackRender = errorData => {
  // Returning only div because when new app version is being deployed vite:preloadError will occur and app will
  // be automatically refreshed (client/index.tsx)
  if (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    IGNORED_ERRORS_FOR_FAILED_TO_FETCH_MODULES.some(regex => regex.test(errorData.error.message))
  ) {
    return <div />;
  }

  return (
    <div className="mx-auto h-64 w-full max-w-[550px] p-2">
      <div className="flex h-full w-full flex-col items-center justify-center border-2 p-2">
        <WarningIcon
          data-testid="error-boundary-warning-icon"
          className="text-error"
          height={64}
          width={64}
        />
        <h4 className="my-4" data-testid="error-boundary-text">
          {i18next.t('common.error_boundary')}
        </h4>
        <Button
          className="w-full sm:w-[60%]"
          onClick={() => window.location.reload()}
          data-testid="error-boundary-reload-button"
        >
          <span className="w-full text-center">{i18next.t('common.error_reload_button')}</span>
        </Button>
      </div>
    </div>
  );
};

export const ErrorBoundary = ({
  children,
  fallback = ErrorBoundaryFallback,
  ...rest
}: ErrorBoundaryProps) => {
  return (
    <SentryErrorBoundary fallback={fallback} {...rest}>
      {children}
    </SentryErrorBoundary>
  );
};
