import ReactModal from 'react-modal';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

import { Button } from 'components/Button/Button';
import { CloseIcon } from 'components/Icons/CloseIcon';
import './modal-styles.css';

export interface ModalProps extends ReactModal.Props {
  title: string;
  description?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onConfirm?: VoidFunction;
  onCancel?: VoidFunction;
}

export const Modal = ({
  title,
  description,
  confirmButtonText,
  cancelButtonText,
  children,
  className,
  onCancel,
  onConfirm,
  onRequestClose,
  ...props
}: ModalProps) => {
  const modalClassName = twMerge(
    clsx(
      'absolute inset-[5%] z-50 m-auto h-fit bg-white shadow-header outline-none sm:w-[600px]',
      className
    )
  );

  return (
    <ReactModal
      className={modalClassName}
      overlayClassName="z-50 fixed top-0 left-0 right-0 bottom-0"
      testId="modal"
      shouldFocusAfterRender={true}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      {...props}
    >
      {onRequestClose && (
        <button
          onClick={onRequestClose}
          type="button"
          data-testid="modal-close-button"
          aria-label="close modal"
          className="absolute right-2 top-2 text-primary hover:text-primaryDark active:text-primaryDark"
        >
          <CloseIcon />
        </button>
      )}
      <h2 className="mx-10 mb-2 mt-8 text-xl" aria-label="modal title">
        {title}
      </h2>
      <div className="max-h-[calc(100vh-20vh)] overflow-auto">
        <div className="px-10 pb-8">
          {description && (
            <p className="text-base" aria-label="modal description">
              {description}
            </p>
          )}
          {children}
        </div>
        {(confirmButtonText || cancelButtonText) && (
          <div
            className="flex flex-row justify-end border-t px-10 py-6"
            data-testid="modal-buttons-container"
          >
            {cancelButtonText && (
              <Button
                type="button"
                variant="tertiary"
                data-testid="modal-cancel-button"
                onClick={onCancel}
              >
                {cancelButtonText}
              </Button>
            )}
            {confirmButtonText && (
              <Button
                type="button"
                className="ml-4"
                data-testid="modal-confirm-button"
                onClick={onConfirm}
              >
                {confirmButtonText}
              </Button>
            )}
          </div>
        )}
      </div>
    </ReactModal>
  );
};
