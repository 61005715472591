import { ApmBase, init as initApm } from '@elastic/apm-rum';
import { nanoid } from 'nanoid';

import { getStorageItem, setStorageItem } from 'utils/localStorage';

import { version } from '../../package.json';

export const APM_USER_ID = 'APM_USER_ID';

declare global {
  interface Window {
    __apm__: ApmBase;
    serverEnvConfig: Record<string, string>;
  }
}

export const initializeApmAgent = () => {
  const {
    ELASTIC_APM_ENABLED,
    ELASTIC_APM_SERVER_URL,
    ELASTIC_APM_SERVICE_NAME,
    ELASTIC_APM_ENVIRONMENT,
    X_FORWARDED_FOR = null
  } = window?.serverEnvConfig ?? {};

  const shouldEnableApmAgent = ELASTIC_APM_ENABLED === 'true' && Boolean(ELASTIC_APM_SERVER_URL);

  if (shouldEnableApmAgent) {
    window.__apm__ = initApm({
      // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
      serviceName: ELASTIC_APM_SERVICE_NAME,

      // Set custom APM Server URL (default: http://localhost:8200)
      serverUrl: ELASTIC_APM_SERVER_URL,

      // Set service version (required for sourcemap feature)
      serviceVersion: version,

      environment: ELASTIC_APM_ENVIRONMENT,

      apmRequest({ xhr }) {
        if (X_FORWARDED_FOR !== null) {
          xhr.setRequestHeader('X-Forwarded-For', X_FORWARDED_FOR);
        }

        return true;
      }
    });

    setApmUserContext();
  }
};

const setApmUserContext = (userContext = {}) => {
  const apm = window.__apm__;

  if (!apm) {
    return;
  }

  let apmUserID = getStorageItem(APM_USER_ID);

  if (!apmUserID) {
    apmUserID = nanoid();
    setStorageItem(APM_USER_ID, apmUserID);
  }

  apm.setUserContext({
    id: apmUserID,
    ...userContext
  });
};
