import { SVGProps } from 'react';

export const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <g clipPath="url(#clip0_43_1862)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0708 6.34317L13.4147 11.9993L19.0708 17.6569L17.6566 19.0711L12.0005 13.4135L6.34292 19.0711L4.92871 17.6569L10.5863 11.9993L4.92871 6.34317L6.34292 4.92896L12.0005 10.5851L17.6566 4.92896L19.0708 6.34317Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_43_1862">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
