import { useTranslation } from 'react-i18next';
import { withRouteTransaction } from 'apm/withTransaction';

import { InfoFilledIcon } from 'components/Icons/InfoFilledIcon';
import { Markdown } from 'components/Markdown/Markdown';

const AlreadyCompletedPage = () => {
  const { t } = useTranslation();
  return (
    <div className="mt-10 flex h-[75vh] max-w-[900px] flex-col items-center bg-white">
      <div className="flex max-w-full basis-12 flex-col">
        <h3 className="mb-4 text-center" data-testid="already-completed-page-info-title">
          <InfoFilledIcon
            className="mr-2 inline text-yellow-500"
            data-testid="already-completed-page-info-title-icon"
          />
          {t('already_done.title')}
        </h3>
        <Markdown
          text={t('already_done.text')}
          className="text-center text-sm text-gray-600"
          data-testid="already-completed-page-info-subtitle"
        />
      </div>
    </div>
  );
};

export default withRouteTransaction('AlreadyCompletedPage')(AlreadyCompletedPage);
