import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from 'i18next/resources';

import { CircleCheckIcon } from 'components/Icons/CircleCheckIcon';
import { HeaderProps } from 'components/Header/Header';

export interface StepperStep {
  title: TranslationKey;
  path: string;
}

interface StepperProps extends Pick<HeaderProps, 'depotTransferProcessStatus'> {
  steps: StepperStep[];
  activeIndex: number;
  onStepClick: (step: StepperStep, index: number) => void;
}

export const Stepper = ({
  steps = [],
  activeIndex,
  depotTransferProcessStatus,
  onStepClick
}: StepperProps) => {
  const { t } = useTranslation();
  return (
    <ul className="flex" data-testid="stepper">
      {steps.map(({ title, path }, index) => (
        <li
          key={`${title}-${index}`}
          className={clsx('flex basis-1/4 bg-white', {
            'hover:bg-gray-100': index < depotTransferProcessStatus
          })}
          data-testid={`stepper-step-${index}`}
        >
          <button
            className={clsx('flex flex-1 border py-4 shadow-header disabled:text-gray-300', {
              'border-b-primary border-b-2': index === activeIndex
            })}
            disabled={index > activeIndex && index + 1 > depotTransferProcessStatus}
            onClick={() => onStepClick({ title, path }, index)}
            data-testid={`stepper-step-button-${index}`}
            type="button"
          >
            <span className="flex flex-1 flex-row items-center justify-center text-center">
              {index < activeIndex && (
                <CircleCheckIcon
                  className="mr-2"
                  data-testid={`stepper-step-completed-icon-${index}`}
                />
              )}
              {`${index + 1}.`}
              <span className="ml-1 hidden md:inline" data-testid={`stepper-step-title-${index}`}>
                {t(title)}
              </span>
            </span>
          </button>
        </li>
      ))}
    </ul>
  );
};
