// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { marked } from 'marked';
import { parse, stringify } from 'himalaya';

const md2json = function (md) {
  const output = marked(md);

  return parse(output);
};

const json2html = function (json) {
  return stringify(json);
};

export { md2json, json2html };
