import { marked } from 'marked';

interface MarkdownProps {
  text?: string;
  htmlTag?: keyof JSX.IntrinsicElements;
  [key: string]: unknown;
}

export const Markdown = ({ text = '', htmlTag = 'div', ...rest }: MarkdownProps) => {
  const html = text && marked.parse(text);

  const Component = htmlTag;

  return <Component dangerouslySetInnerHTML={{ __html: html }} {...rest} />;
};
