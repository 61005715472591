import axios, { AxiosRequestConfig } from 'axios';

export const apiClient = axios.create({
  withCredentials: import.meta.env.MODE === 'production',
  baseURL: import.meta.env.MODE === 'production' ? '/psk_core/' : 'http://localhost:7777/',
  headers: {
    'Content-type': 'application/json'
  }
});

let csrfToken = '';
const mockApiCsrfToken = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export const getAuthToken = () =>
  import.meta.env.MODE === 'production' ? csrfToken : mockApiCsrfToken;

export const get = async (url: string, config?: AxiosRequestConfig) => {
  const { data, headers } = await apiClient.get(url, config);

  csrfToken = headers['x-psk-core-api'];

  return data;
};

export interface Post {
  url: string;
  payload: unknown;
  config?: AxiosRequestConfig;
}

export const post = async ({ url, payload, config = {} }: Post) => {
  const _config = {
    ...config,
    headers: {
      ...config?.headers,
      ['X-CSRF-Token']: getAuthToken()
    }
  };

  const { data, headers } = await apiClient.post(url, payload, _config);

  csrfToken = headers['x-psk-core-api'];

  return data;
};
