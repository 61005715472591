import {
  Route,
  Navigate,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  ScrollRestoration,
  Outlet,
  createMemoryRouter
} from 'react-router-dom';
import { lazy, Suspense } from 'react';

import { DepotTransfer } from 'models/DepotTransfer';
import AlreadyCompletedPage from 'components/AlreadyCompletedPage/AlreadyCompletedPage';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';

import { HeaderFooterLayout } from 'routes/HeaderFooterLayout';
import { routes } from 'routes/routes';
import { ProtectedRoute } from 'routes/ProtectedRoute';
import { ParsedTenant } from 'routes/RouterContainer';

const HomePageContainer = lazy(() => import('components/HomePage/HomePageContainer'));
const PersonalDetailsContainer = lazy(
  () => import('components/PersonalDetails/PersonalDetailsContainer')
);
const TransferTypePageContainer = lazy(
  () => import('components/TransferTypePage/TransferTypePageContainer')
);
const DepotStocksPageContainer = lazy(
  () => import('components/DepotStocksPage/DepotStocksPageContainer')
);
const SummaryPageContainer = lazy(() => import('components/SummaryPage/SummaryPageContainer'));
const DonePageContainer = lazy(() => import('components/DonePage/DonePageContainer'));
const SignHerePageContainer = lazy(() => import('components/SignHerePage/SignHerePageContainer'));

export interface RouterProps {
  tenant: ParsedTenant;
  depotTransfer?: DepotTransfer;
}

const LayoutWithScrollRestoration = () => {
  return (
    <>
      <ScrollRestoration />
      <Outlet />
    </>
  );
};

export const Router = ({ tenant, depotTransfer, ...props }: RouterProps) => {
  const createRouter = import.meta.env.MODE === 'test' ? createMemoryRouter : createBrowserRouter;

  const router = createRouter(
    createRoutesFromElements(
      <>
        <Route
          element={
            <ErrorBoundary>
              <LayoutWithScrollRestoration />
            </ErrorBoundary>
          }
        >
          <Route path={routes.signHere} element={<SignHerePageContainer />} />
          <Route element={<ProtectedRoute depotTransfer={depotTransfer} />}>
            <Route
              element={
                <HeaderFooterLayout
                  logoSrc={tenant.logo_url}
                  faqData={tenant.faq_content}
                  tenantName={tenant.bank_name}
                  depotTransferProcessStatus={depotTransfer?.process_status ?? 0}
                />
              }
            >
              <Route path={routes.home} element={<HomePageContainer />} />
              <Route path={routes.personalData} element={<PersonalDetailsContainer />} />
              <Route path={routes.transferType} element={<TransferTypePageContainer />} />
              <Route path={routes.depotStocks} element={<DepotStocksPageContainer />} />
              <Route path={routes.summary} element={<SummaryPageContainer />} />
              <Route path={routes.done} element={<DonePageContainer />} />
              <Route path={routes.alreadyCompleted} element={<AlreadyCompletedPage />} />
            </Route>
          </Route>
          <Route path="*" element={<Navigate to={routes.home} replace />} />
        </Route>
      </>
    )
  );

  return (
    <Suspense fallback={null}>
      <RouterProvider router={router} {...props} />
    </Suspense>
  );
};
