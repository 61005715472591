import { useQuery } from '@tanstack/react-query';
import { get } from 'apis/apiClient';
import { API_PATH } from 'apis/apiPath';
import { AxiosError } from 'axios';
import { BrowserRouterProps, MemoryRouterProps } from 'react-router-dom';

import { Tenant } from 'models/Tenant';
import { DepotTransfer } from 'models/DepotTransfer';
import { LoaderIcon } from 'components/Icons/LoaderIcon';
import { Faq } from 'components/Header/Header';

import { QUERY_KEYS } from 'enums/queryKeys';

import { Router } from 'routes/Router';

import { parseFaqContent } from 'utils/parseFaqContent';

export type ParsedTenant = Tenant & { faq_content: Faq[] };

export const RouterContainer = (props: MemoryRouterProps | BrowserRouterProps) => {
  const { isLoading: isTenantLoading, data: tenant } = useQuery<Tenant, AxiosError<Tenant>>({
    queryKey: [QUERY_KEYS.TENANT],
    queryFn: () =>
      get(API_PATH.TENANT, {
        ...(import.meta.env.MODE === 'development'
          ? { headers: { 'X-Tenant-Host': 'localhost' } }
          : {})
      }),
    select: tenant => ({
      ...tenant,
      faq_content: parseFaqContent(tenant.faq_content)
    })
  });

  const { isLoading: isDepotLoading, data: depotTransfer } = useQuery<
    DepotTransfer,
    AxiosError<DepotTransfer>
  >({
    queryKey: [QUERY_KEYS.DEPOT_TRANSFER],
    queryFn: () => get(API_PATH.DEPOT_TRANSFER)
  });

  if (isTenantLoading || isDepotLoading) {
    return (
      <div
        data-testid="loading-indicator"
        className="fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-lightPrimary/50"
      >
        <LoaderIcon />
      </div>
    );
  }

  return tenant ? (
    <Router tenant={tenant as ParsedTenant} depotTransfer={depotTransfer} {...props} />
  ) : null;
};
