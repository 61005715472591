import 'tailwindcss/tailwind.css';
import 'styles/main.css';
import 'i18next/i18next';

import { createRoot } from 'react-dom/client';
import { initializeApmAgent } from 'apm/apmAgent';
import { initializeSentryAgent } from 'apm/sentryAgent';

import App from 'components/App';

// https://vitejs.dev/guide/build#load-error-handling
window.addEventListener('vite:preloadError', () => {
  window.location.reload();
});

initializeApmAgent();
initializeSentryAgent();

const container = document.getElementById('root') as HTMLDivElement;
const root = createRoot(container);

root.render(<App />);
