import { SVGProps } from 'react';

export const CircleCheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" width="24" height="24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM15.0079 8L16.3497 9.48314L10.1446 15.0966L7 12.1899L8.35756 10.7212L10.1592 12.3865L15.0079 8Z"
      fill="currentColor"
    />
  </svg>
);
