export const WarningIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" width="24" height="24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 22L12 0L0 22H24ZM13 18V16H11V18H13ZM13 15V9H11V15H13Z"
        fill="currentColor"
      />
    </svg>
  );
};
