export const ArrowIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 8C11.7832 8.00368 9.4499 10.4799 5 15.4285L6.46269 16.9999L10.9552 12.1736L12 11.1123L13.0448 12.1736L17.5373 16.9999L19 15.4285L15.6072 11.7256C13.311 9.24001 12.1086 7.99816 12 8Z"
      fill="currentColor"
    />
  </svg>
);
