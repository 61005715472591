import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { PROCESS_STATUS } from 'enums/processStatus';

import { routes } from 'routes/routes';
import { RouterProps } from 'routes/Router';

type ProtectedRouteProps = Pick<RouterProps, 'depotTransfer'>;

const processStatusRouteMap: Record<number, string> = {
  [PROCESS_STATUS.LOGIN]: routes.home,
  [PROCESS_STATUS.PERSONAL_DATA]: routes.personalData,
  [PROCESS_STATUS.TRANSFER_TYPE]: routes.transferType,
  [PROCESS_STATUS.STOCKS]: routes.depotStocks,
  [PROCESS_STATUS.SUMMARY]: routes.summary,
  [PROCESS_STATUS.DONE]: routes.done
};

const pathnameToProcessStatusMap: Record<string, number> = {
  [routes.home]: PROCESS_STATUS.LOGIN,
  [routes.personalData]: PROCESS_STATUS.PERSONAL_DATA,
  [routes.transferType]: PROCESS_STATUS.TRANSFER_TYPE,
  [routes.depotStocks]: PROCESS_STATUS.STOCKS,
  [routes.summary]: PROCESS_STATUS.SUMMARY,
  [routes.done]: PROCESS_STATUS.DONE
};

const allowedRoutes: string[] = [routes.home, routes.alreadyCompleted];

export const ProtectedRoute = ({ depotTransfer }: ProtectedRouteProps) => {
  const { pathname, state } = useLocation();

  if (allowedRoutes.includes(pathname)) {
    return <Outlet />;
  }

  if (!depotTransfer) {
    return <Navigate to={routes.home} />;
  }

  const { process_status } = depotTransfer;

  if (process_status === PROCESS_STATUS.DONE && pathname !== routes.done) {
    return <Navigate to={routes.alreadyCompleted} />;
  }

  // Redirect from summary to done page with submitSummary button
  if (process_status === PROCESS_STATUS.SUMMARY && state?.completed) {
    return <Outlet />;
  }

  const processStatusRoute = processStatusRouteMap[process_status];

  if (pathname !== processStatusRoute && pathnameToProcessStatusMap[pathname] >= process_status) {
    return <Navigate to={processStatusRoute} />;
  }

  return <Outlet />;
};
