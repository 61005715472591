export type Route = (typeof routes)[keyof typeof routes];

export const routes = {
  home: '/',
  personalData: '/personal-data',
  transferType: '/transfer-type',
  depotStocks: '/depot-stocks',
  summary: '/summary',
  done: '/done',
  alreadyCompleted: '/already-completed',
  signHere: '/sign-here'
} as const;
