export const QUERY_KEYS = {
  TENANT: 'TENANT',
  DEPOT_TRANSFER: 'DEPOT_TRANSFER',
  BANKS_SEARCH: 'BANKS_SEARCH',
  BANK_LOGIN_FIELDS: 'BANK_LOGIN_FIELDS',
  REG_SHIELD_LOCATION: 'REG_SHIELD_LOCATION',
  STOCKS: 'STOCKS',
  REG_SHIELD_TOKEN: 'REG_SHIELD_TOKEN',
  PRIMARY_HOLDER_PHONE_SIGNATURE: 'PRIMARY_HOLDER_PHONE_SIGNATURE',
  SECONDARY_HOLDER_PHONE_SIGNATURE: 'SECONDARY_HOLDER_PHONE_SIGNATURE',
  PDF_SUMMARY_GENERATED: 'PDF_SUMMARY_GENERATED',
  DOWNLOAD_PDF_SUMMARY: 'DOWNLOAD_PDF_SUMMARY'
} as const;

export const MUTATION_KEYS = {
  CREATE_DEPOT_TRANSFERS: 'CREATE_DEPOT_TRANSFERS',
  CREATE_FULL_DEPOT_TRANSFERS: 'CREATE_FULL_DEPOT_TRANSFERS',
  CREATE_REG_SHIELD_DEPOT_TRANSFERS: 'CREATE_REG_SHIELD_DEPOT_TRANSFERS',
  ADD_PERSONAL_DATA: 'ADD_PERSONAL_DATA',
  ADD_TRANSFER_TYPE: 'ADD_TRANSFER_TYPE',
  ADD_STOCKS: 'ADD_STOCKS',
  CLOSE_ACCOUNT_INFO: 'CLOSE_ACCOUNT_INFO',
  SEND_SIGNATURE_LINK_TO_PRIMARY_HOLDER: 'SEND_SIGNATURE_LINK_TO_PRIMARY_HOLDER',
  SEND_SIGNATURE_LINK_TO_SECONDARY_HOLDER: 'SEND_SIGNATURE_LINK_TO_SECONDARY_HOLDER',
  SUBMIT_SUMMARY: 'SUBMIT_SUMMARY',
  SEND_EMAIL_WITH_SUMMARY: 'SEND_EMAIL_WITH_SUMMARY',
  STORE_SIGNATURE: 'STORE_SIGNATURE'
} as const;
