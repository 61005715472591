import { Outlet } from 'react-router-dom';

import { Header, HeaderProps } from 'components/Header/Header';
import { Footer, FooterProps } from 'components/Footer/Footer';

type HeaderFooterLayoutProps = HeaderProps & FooterProps;

// children prop is used only in storybook, Outlet is actual route (page)
export const HeaderFooterLayout = ({
  logoSrc,
  faqData,
  tenantName,
  depotTransferProcessStatus,
  children
}: HeaderFooterLayoutProps) => {
  return (
    <div className="flex h-full w-full flex-col justify-between">
      <Header
        logoSrc={logoSrc}
        faqData={faqData}
        depotTransferProcessStatus={depotTransferProcessStatus}
      />
      <div className="flex flex-auto flex-col items-center justify-start p-4 md:p-10">
        <Outlet />
        {children}
      </div>
      <Footer tenantName={tenantName} />
    </div>
  );
};
