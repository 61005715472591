import { StrictMode } from 'react';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ToastContainer } from 'react-toastify';
import { AxiosError, isAxiosError } from 'axios';
import i18next from 'i18next/i18next';

import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';

import { QUERY_KEYS } from 'enums/queryKeys';

import { RouterContainer } from 'routes/RouterContainer';

import { triggerToast } from 'utils/triggerToast';

export const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false } },
  queryCache: new QueryCache({
    onError: (error: Error | AxiosError, query) => {
      const [queryKey] = query.queryKey;
      const _isAxiosError = isAxiosError(error);

      if (!_isAxiosError) {
        return;
      }

      const errorStatus = error?.response?.status;
      const genericErrorMessage = i18next.t('common.error');

      switch (queryKey) {
        case QUERY_KEYS.DEPOT_TRANSFER:
          if (errorStatus === 404) {
            return;
          }
          triggerToast(genericErrorMessage, 'ERROR');
          break;
        case QUERY_KEYS.PRIMARY_HOLDER_PHONE_SIGNATURE:
        case QUERY_KEYS.SECONDARY_HOLDER_PHONE_SIGNATURE:
          if (errorStatus === 304) {
            return;
          }
          triggerToast(genericErrorMessage, 'ERROR');
          break;
        default:
          triggerToast(genericErrorMessage, 'ERROR');
      }
    }
  }),
  mutationCache: new MutationCache({
    onError: () => {
      triggerToast(i18next.t('common.error'), 'ERROR');
    }
  })
});

function App() {
  return (
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary>
          <RouterContainer />
          <ToastContainer />
        </ErrorBoundary>
        <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
      </QueryClientProvider>
    </StrictMode>
  );
}

export default App;
