import { HTMLAttributes, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button/Button';
import { Modal } from 'components/Modal/Modal';
import { ArrowIcon } from 'components/Icons/Arrow';
import { Stepper, StepperStep } from 'components/Stepper/Stepper';
import { steps } from 'components/Stepper/steps';
import { Markdown } from 'components/Markdown/Markdown';
import { DepotTransfer } from 'models/DepotTransfer';
import { QuestionMarkIcon } from 'components/Icons/QuestionMarkIcon';

import { routes } from 'routes/routes';

export interface Faq {
  question: string;
  answer: string;
}

export interface HeaderProps extends HTMLAttributes<HTMLElement> {
  logoSrc: string;
  faqData: Faq[];
  depotTransferProcessStatus: DepotTransfer['process_status'];
}

export const Header = ({ faqData, logoSrc, depotTransferProcessStatus, ...props }: HeaderProps) => {
  const { t } = useTranslation();
  const [isFaqModalVisible, setIsFaqModalVisible] = useState(false);

  const toggleFaq = () => {
    setIsFaqModalVisible(!isFaqModalVisible);
  };

  const location = useLocation();
  const navigate = useNavigate();

  const activeIndex = steps.findIndex(step => step.path === location.pathname);

  const showStepper = activeIndex !== -1;

  const onStepClick = (step: StepperStep) => {
    navigate(step.path);
  };

  return (
    <header className="sticky top-0 z-10" {...props}>
      <div
        data-testid="header-content-container"
        className="flex h-[80px] flex-row items-center justify-between bg-lightSecondary px-8 py-3 md:px-5 lg:px-20 xl:px-28"
      >
        <div className="flex h-full flex-row items-center">
          <Link to={routes.home} aria-label="open company website">
            <img
              className="w-48 pr-5 md:border-r lg:w-56"
              src="/assets/company-logo.png"
              alt="Company logo"
              data-testid="company-logo"
            />
          </Link>
          <span
            className="hidden text-sm italic text-grayPrimary md:mx-2 md:block lg:mx-5"
            data-testid="corporate-text"
          >
            {t('common.in_cooperation')}
          </span>
          <img
            className="hidden max-h-full w-auto max-w-[191px] md:block"
            src={logoSrc}
            alt="Tenant logo"
            data-testid="tenant-logo"
          />
        </div>
        <Button
          className="hidden sm:block"
          type="button"
          data-testid="button-open-faq-modal"
          variant="secondary"
          onClick={toggleFaq}
        >
          {t('faq.title')}
        </Button>
        <button
          type="button"
          data-testid="icon-button-open-faq-modal"
          onClick={toggleFaq}
          className="block sm:hidden"
          aria-label="Open FAQ Modal"
        >
          <QuestionMarkIcon width={35} height={35} className="text-primary" />
        </button>
      </div>

      {showStepper && (
        <div className="sticky w-full bg-stepper-linear-gradient">
          <div className="m-auto max-w-[1140px]">
            <Stepper
              steps={steps}
              activeIndex={activeIndex}
              onStepClick={onStepClick}
              depotTransferProcessStatus={depotTransferProcessStatus}
            />
          </div>
        </div>
      )}

      <Modal
        title={t('faq.general_description')}
        onRequestClose={toggleFaq}
        isOpen={isFaqModalVisible}
        contentLabel="FAQ modal"
      >
        <Accordion className="mt-4" allowZeroExpanded allowMultipleExpanded>
          {faqData.map(({ question, answer }, index) => {
            return (
              <AccordionItem key={`faq:${question}`}>
                <AccordionItemHeading>
                  <AccordionItemButton
                    data-testid={`faq-question-${index}`}
                    className="group mb-4 flex cursor-pointer flex-row justify-between p-4 text-base text-black shadow-header hover:bg-lightSecondary active:bg-lightSecondary aria-expanded:mb-0 aria-selected:mb-0"
                  >
                    {question}
                    <ArrowIcon className="rotate-180 text-primary transition-all duration-200 group-aria-expanded:rotate-0 group-aria-selected:rotate-0" />
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel
                  data-testid={`faq-answer-${index}`}
                  className="animate-fadeOpacity p-5 text-sm"
                >
                  <Markdown text={answer} />
                </AccordionItemPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      </Modal>
    </header>
  );
};
