export const QuestionMarkIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" width="24" height="24" fill="white" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0ZM12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5ZM12.8745 15.2895V17.0445H11.106V15.2895H12.8745ZM11.9025 7.5C12.4515 7.5 12.9555 7.6035 13.4145 7.8105C13.8735 8.0175 14.2402 8.32125 14.5147 8.72175C14.7893 9.12225 14.9265 9.6015 14.9265 10.1595C14.9265 10.6275 14.85 11.0122 14.697 11.3138C14.544 11.6153 14.364 11.8492 14.157 12.0157C13.95 12.1823 13.6935 12.3465 13.3875 12.5085C13.1265 12.6525 12.9398 12.7762 12.8272 12.8797C12.7147 12.9833 12.6585 13.1115 12.6585 13.2645V14.223H11.2815V13.251C11.2815 12.882 11.34 12.5805 11.457 12.3465C11.574 12.1125 11.7157 11.9303 11.8822 11.7997C12.0488 11.6692 12.267 11.532 12.537 11.388C12.861 11.208 13.104 11.0348 13.266 10.8682C13.428 10.7017 13.509 10.4565 13.509 10.1325C13.509 9.7005 13.3695 9.37875 13.0905 9.16725C12.8115 8.95575 12.4335 8.85 11.9565 8.85C10.9395 8.85 10.431 9.38099 10.431 10.443H9C9 9.525 9.26325 8.805 9.78975 8.283C10.3163 7.761 11.0205 7.5 11.9025 7.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
