import { SVGProps } from 'react';

export const InfoFilledIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0ZM13.166 6V8.34H10.808V6H13.166ZM13.166 18V10H10.808V18H13.166Z"
      fill="currentColor"
    />
  </svg>
);
