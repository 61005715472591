export const API_PATH = {
  TENANT: 'api/v1/tenant',
  DEPOT_TRANSFER: 'api/v1/depot_transfer',
  BANKS_SEARCH: 'banks/search',
  BANK_LOGIN_FIELDS: 'login_fields',
  CREATE_DEPOT_TRANSFERS: 'create_depot_transfers',
  CREATE_FULL_DEPOT_TRANSFERS: 'full_depot_transfers',
  CREATE_REG_SHIELD_DEPOT_TRANSFERS: 'regshield_depot_transfers',
  REG_SHIELD_LOCATION: 'location',
  REG_SHIELD_TOKEN: 'token',
  ADD_PERSONAL_DATA: 'add_personal_data',
  ADD_TRANSFER_TYPE: 'add_transfer_type',
  GET_STOCKS: 'api/v1/stocks',
  ADD_STOCKS: 'add_stocks',
  CLOSE_ACCOUNT_INFO: 'close_account_info',
  POLL_PHONE_SIGNATURE: 'poll_phone_signature',
  SEND_SIGNATURE_LINK: 'api/v1/send_signature_link',
  SUBMIT_SUMMARY: 'submit_summary',
  PDF_SUMMARY_GENERATED: 'pdf_summary_generated',
  DOWNLOAD_PDF_SUMMARY: 'api/v1/summary',
  SEND_EMAIL_WITH_SUMMARY: 'api/v1/email_with_summary',
  STORE_SIGNATURE: 'api/v1/signature'
} as const;
