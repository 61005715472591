import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import PhraseInContextEditorPostProcessor from 'i18next-phrase-in-context-editor-post-processor';

i18n.use(initReactI18next).use(HttpApi);

const isPhraseEditorEnabled = import.meta.env.VITE_PHRASE_EDITOR_ENABLED;

if (isPhraseEditorEnabled) {
  i18n.use(
    new PhraseInContextEditorPostProcessor({
      phraseEnabled: true,
      projectId: import.meta.env.VITE_PHRASE_PROJECT_ID,
      accountId: import.meta.env.VITE_PHRASE_ACCOUNT_ID,
      useOldICE: true,
      prefix: '{{__phrase_',
      suffix: '__}}'
    })
  );
}

i18n.init({
  debug: import.meta.env.MODE === 'development',
  lng: 'de',
  fallbackLng: 'de',
  backend: {
    loadPath:
      import.meta.env.MODE === 'production'
        ? '/locales/{{lng}}/{{ns}}'
        : 'http://localhost:7777/locales/{{lng}}/{{ns}}'
  },
  ...(isPhraseEditorEnabled ? { postProcess: ['phraseInContextEditor'] } : {})
});

export default i18n;
