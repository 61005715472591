import { ButtonHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  variant?: 'primary' | 'secondary' | 'tertiary';
  icon?: React.ReactElement;
}

export const Button = ({
  children,
  icon,
  variant = 'primary',
  className,
  disabled,
  ...rest
}: ButtonProps) => {
  const btnClass = twMerge(
    clsx(
      'px-6',
      'py-3',
      'rounded-full',
      'flex-row',
      'flex',
      'items-center',
      'disabled:cursor-auto disabled:opacity-60',
      {
        'bg-primary text-white': variant === 'primary',
        'border border-primary bg-white text-primary': variant === 'secondary',
        'hover:bg-primaryDark': variant === 'primary',
        'active:bg-primaryDark': variant === 'primary',
        'hover:bg-primary active:bg-primary': disabled && variant === 'primary',
        'hover:bg-primaryExtraLight hover:border-primaryDark hover:text-primaryDark':
          variant === 'secondary',
        'active:bg-primaryExtraLight active:border-primaryDark active:text-primaryDark':
          variant === 'secondary',
        'border-none bg-white text-primary hover:bg-lightPrimary active:bg-lightPrimary':
          variant === 'tertiary',
        'hover:bg-white active:bg-white':
          disabled && (variant === 'tertiary' || variant === 'secondary')
      },
      'transition-all duration-200',
      className
    )
  );

  return (
    <button className={btnClass} disabled={disabled} {...rest}>
      {icon && <span className="mr-2">{icon}</span>}
      {children}
    </button>
  );
};
