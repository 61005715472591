import { TranslationKey } from 'i18next/resources';

import { routes } from 'routes/routes';

export const steps: { title: TranslationKey; path: string }[] = [
  { title: 'navbar.personal_data', path: routes.personalData },
  { title: 'navbar.transfer_type', path: routes.transferType },
  { title: 'navbar.depot_stocks', path: routes.depotStocks },
  { title: 'navbar.confirm', path: routes.summary }
];
