import { toast } from 'react-toastify';
import { ToastContentProps, ToastOptions } from 'react-toastify/dist/types';
import clsx from 'clsx';
import { CloseButtonProps } from 'react-toastify/dist/components';

import { CloseIcon } from 'components/Icons/CloseIcon';
import { CircleCheckIcon } from 'components/Icons/CircleCheckIcon';
import { WarningIcon } from 'components/Icons/WarningIcon';
import { ErrorIcon } from 'components/Icons/ErrorIcon';
import { Markdown } from 'components/Markdown/Markdown';

import 'react-toastify/dist/ReactToastify.min.css';

export type ToastType = 'SUCCESS' | 'WARNING' | 'ERROR';

export const triggerToast = (message: string, type: ToastType, options?: ToastOptions) => {
  const Content = ({ toastProps: { toastId } }: ToastContentProps) => (
    <div data-testid={`toast-content-${toastId}`} className="flex items-center text-sm">
      <div>
        {type === 'SUCCESS' && (
          <CircleCheckIcon data-testid="toast-success-icon" className="text-successLight" />
        )}
        {type === 'WARNING' && (
          <WarningIcon data-testid="toast-warning-icon" className="text-warningLight" />
        )}
        {type === 'ERROR' && (
          <ErrorIcon data-testid="toast-error-icon" className="text-errorLight" />
        )}
      </div>
      <Markdown data-testid="toast-message" className="ml-4 text-white" text={message} />
    </div>
  );

  const CloseButton = ({ closeToast }: CloseButtonProps) => (
    <button
      type="button"
      aria-label="toast close button"
      data-testid="toast-close-button"
      className={clsx({
        'text-successLight': type === 'SUCCESS',
        'text-warningLight': type === 'WARNING',
        'text-errorLight': type === 'ERROR'
      })}
      onClick={closeToast}
    >
      <CloseIcon width={20} height={20} />
    </button>
  );

  const containerClassName = clsx('!rounded-none', {
    '!bg-success': type === 'SUCCESS',
    '!bg-warning': type === 'WARNING',
    '!bg-error': type === 'ERROR'
  });

  return toast(Content, {
    closeOnClick: false,
    draggable: false,
    className: containerClassName,
    autoClose: 5000,
    closeButton: CloseButton,
    hideProgressBar: true,
    ...options
  });
};
