import { md2json, json2html } from 'utils/jsonizer';

export const parseFaqContent = (text: string | null) => {
  if (!text) {
    return [];
  }

  let json = md2json(text);
  json = cleanEmptyNodes(json);
  json = flattenNodes(json);

  return json.map((item: FlattenedNode) => ({
    question: item.header,
    answer: json2html(item.content)
  }));
};

type ElementNode = {
  type: 'element';
  tagName: string;
  attributes: Record<string, unknown>;
  children: { type: string; content: string }[];
};

type TextNode = {
  type: 'text';
  content: string;
};

export type Node = ElementNode | TextNode;

export type FlattenedNode = {
  header: string;
  content: Node[];
};

export const cleanEmptyNodes = (nodes: Node[] = []) => {
  return nodes.filter(node => {
    return node.type === 'text' ? Boolean(node.content.trim().length) : true;
  });
};

export const flattenNodes = (nodes: Node[] = []) => {
  const [firstNode] = nodes;
  if (firstNode?.type !== 'element' || firstNode?.tagName !== 'h2') {
    console.error(
      'Markdown Error: Malformed markdown of FAQ. Should start with `h2` tag (** || ##).'
    );

    return [];
  }

  const collection: FlattenedNode[] = [];
  let currentNode: FlattenedNode = {
    header: '',
    content: []
  };

  nodes.forEach(node => {
    if (node.type === 'element' && node.tagName === 'h2') {
      currentNode = {
        header: node.children[0]?.content,
        content: []
      };

      collection.push(currentNode);
    } else {
      currentNode.content.push(node);
    }
  });

  return collection;
};
